import React, { useState, useEffect } from "react";
import AdobePhotoshopImage from "../../../assets/icons/photoshop.svg";
import AdobeIllustratorImage from "../../../assets/icons/illustrator.svg";
import StarFilledImage from "../../../assets/icons/star-filled.svg";
import StarImage from "../../../assets/icons/star.svg";
import RatingStars from "../../RatingStars/RatingStars";
import "./FourthSection.scss";
import { useLocation } from "react-router-dom";
import FooterSection from "../FooterSection/FooterSection";

const FourthSection = ({ userData, setMyUserData }) => {
  const [rating, setRating] = useState();

  const handleStarClick = (nextValue) => {
    setRating(nextValue);
  };

  useEffect(() => {
    console.log("parent");
  }, []);

  const location = useLocation();
  const [skillsImages, setSkillsImages] = useState(null);
  const [skillsTitles, setSkillsTitles] = useState(null);

  useEffect(() => {
    let skills = location.state.skills;
    let skillsImagesNoSpaces = [];
    skills.forEach((skill) => {
      skillsImagesNoSpaces.push(skill.replace(/\s/g, "") + "Image");
    });
    setSkillsImages(skillsImagesNoSpaces);
    setSkillsTitles(skills);
  }, [location.state.skills]);

  // useEffect(() => {
  //     fetch('http://127.0.0.1:5500/src/jobs.json')
  //     .then(response => response.json())
  //     .then(({positions}) => {
  //         let skills = positions[0].skills
  //         let skillsImagesNoSpaces = []
  //         skills.forEach(skill => {
  //             skillsImagesNoSpaces.push(skill.replace(/\s/g, '')+"Image");
  //         });
  //         setSkillsImages(skillsImagesNoSpaces)
  //         setSkillsTitles(skills)
  //     })
  //     .catch(error => console.error(error));
  // }, [])

  const PickImage = (image) => {
    switch (image) {
      case "AdobePhotoshopImage":
        return AdobePhotoshopImage;
      case "AdobeIllustratorImage":
        return AdobeIllustratorImage;
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <div className="parent-form title">
          <p>Rate your knowledge skills</p>
          <hr />
        </div>
        <div className="parent-form-knowledge">
          {skillsImages &&
            skillsImages.map((image, index) => (
              <div className="key-item" key={Math.random()}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      className="image-tool"
                      src={PickImage(image)}
                      alt="current level"
                    />
                  </div>
                  <div>
                    <div style={{ marginLeft: "1rem", textAlign: "start" }}>
                      <div>
                        <p className="tool-title">{skillsTitles[index]}</p>
                      </div>
                      <div>
                        <RatingStars
                          iconFilled={StarFilledImage}
                          icon={StarImage}
                          skill={skillsTitles[index]}
                          onStarClick={handleStarClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <FooterSection
        nextButton={["fourth", "fifth"]}
        previousButton={["fourth", "third"]}
      />
    </>
  );
};

export default FourthSection;
