import "../Form/Form.scss";
import FirstSection from "./FirstSection/FirstSection";
import SecondSection from "./SecondSection/SecondSection";
import ThirdSection from "./ThirdSection/ThirdSection";
import FourthSection from "./FourthSection/FourthSection";
import FifthSection from "./FifthSection/FifthSection";
import SixthSection from "./SixthSection/SixthSection";
import FooterSection from "./FooterSection/FooterSection";
import { useState } from "react";
// import StepWizard from "react-step-wizard"

// import CurrentLevelLabelImage from '../../assets/current-level-label.png'
// import * as EmailValidator from 'email-validator';

export default function Form() {
  const [userData, setUserData] = useState({
    url: "",
    file: "",
    name: "",
    email: "",
    phone: "",
    salary: 0,
    startDate: "",
    whyYouApply: "",
  });

  const setMyUserData = (newValue) => {
    setUserData(newValue);
  };

  return (
    <div id="form-top">
      <div className="form-first-section">
        <h1 className="section-title">Fill the form</h1>
        <div className="container-form">
          <div className="parent-form">
            <FirstSection userData={userData} setMyUserData={setMyUserData} />
          </div>
        </div>
      </div>
      <div className="form-second-section">
        <h1 className="section-title">Personal Infomation</h1>
        <div className="container-form">
          <div className="parent-form">
            <SecondSection userData={userData} setMyUserData={setMyUserData} />
          </div>
        </div>
      </div>
      <div className="form-third-section">
        <h1 className="section-title">Questions</h1>
        <div className="container-form">
          <div className="parent-form">
            <ThirdSection userData={userData} setMyUserData={setMyUserData} />
          </div>
        </div>
      </div>
      <div className="form-fourth-section">
        <h1 className="section-title">Your Skills</h1>
        <div className="container-form">
          <div className="parent-form">
            <FourthSection userData={userData} setMyUserData={setMyUserData} />
          </div>
        </div>
      </div>
      <div className="form-fifth-section">
        <h1 className="section-title">Your Interests</h1>
        <div className="container-form">
          <div className="parent-form">
            <FifthSection />
            <FooterSection
              nextButton={["fifth", "sixth"]}
              previousButton={["fifth", "fourth"]}
            />
          </div>
        </div>
      </div>
      <div className="form-sixth-section">
        <div className="container-form" style={{ height: "fit-content" }}>
          <div className="parent-form">
            <SixthSection />
          </div>
        </div>
      </div>
    </div>
  );
}
