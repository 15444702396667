import "../JobPositions/JobPosition.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function JobPosition({ cityFilter, data }) {
  useEffect(() => {
    console.log("cityFilter", cityFilter);
  }, [cityFilter]);

  const navigate = useNavigate();

  const [positionsData, setPositionsData] = useState(null);

  useEffect(() => {
    if (cityFilter === "All") {
      setPositionsData(data.positions);
    } else {
      const filteredItems = data.positions.filter(
        (item) => item.location === cityFilter
      );

      setPositionsData(filteredItems);
    }
  }, [cityFilter, data.positions]);

  return (
    <div>
      {positionsData &&
        positionsData.map((position) => (
          <div key={position.id} className="containerJ">
            <div className="headerJ">
              <div className="headerJ-parent">
                <div className="iconJ">
                  <img className="zailabIcon2" alt="logo" />
                </div>
                <div className="title-parent">
                  <p className="title">{position.title}</p>
                  <p className="description desktop-only">
                    Qualification: {position.qualification}
                    <br />
                    Company: {position.company}
                  </p>
                </div>
              </div>
            </div>
            <div className="descriptionJ">
              <div className="qualifications mobile-only">
                <p className="qualifications-text">
                  Qualification: {position.qualification}
                </p>
                <p className="qualifications-text">
                  Company {position.company}
                </p>
              </div>
              <div className="time-date-desc">
                <p className="time-date-desc-text">{position.type}</p>
              </div>
              <div className="time-date-desc">
                <p className="time-date-desc-text">{position.date}</p>
              </div>
              <div className="button-parent">
                <button
                  className="buttonJ"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("description", { state: position })}
                >
                  Read
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
