import React from "react";
import "./SixthSection.scss";

const SixthSection = () => {
  return (
    <div>
      <div className="parent-form title">
        <p className="url-input-text">Thank you for applying 🎉</p>
      </div>
    </div>
  );
};

export default SixthSection;
