import React, { useEffect, useRef } from "react";
import "./FooterSection.scss";

const FooterSection = ({ nextButton, previousButton, error }) => {
  if (error) {
    nextButton[1] = nextButton[0];
  }

  const footerBarRef = useRef(null);
  const progressBubbleFirst = useRef(null);
  const progressBubbleTextFirst = useRef(null);

  const progressBubbleSecond = useRef(null);
  const progressBubbleTextSecond = useRef(null);

  const progressBubbleThird = useRef(null);
  const progressBubbleTextThird = useRef(null);

  const progressBubbleFourth = useRef(null);
  const progressBubbleTextFourth = useRef(null);

  const progressBubbleFifth = useRef(null);
  const progressBubbleTextFifth = useRef(null);

  useEffect(() => {
    if (nextButton[0] === "first") {
      progressBubbleTextFirst.current.style.opacity = "100%";
      progressBubbleFirst.current.style.border =
        "3px solid rgba(0, 149, 198,1)";
    } else if (nextButton[0] === "second") {
      footerBarRef.current.classList.add("sep2");
      progressBubbleTextFirst.current.style.opacity = "100%";
      progressBubbleTextFirst.current.style.color = "white";
      progressBubbleFirst.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleFirst.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";

      progressBubbleTextSecond.current.style.opacity = "100%";
      progressBubbleSecond.current.style.border =
        "3px solid rgba(0, 149, 198,1)";
    } else if (nextButton[0] === "third") {
      progressBubbleTextFirst.current.style.opacity = "100%";
      progressBubbleTextFirst.current.style.color = "white";
      progressBubbleFirst.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleFirst.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";
      footerBarRef.current.classList.add("sep3");
      footerBarRef.current.classList.add("sep2");
      progressBubbleTextSecond.current.style.opacity = "100%";
      progressBubbleTextSecond.current.style.color = "white";
      progressBubbleSecond.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleSecond.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";

      progressBubbleTextThird.current.style.opacity = "100%";
      progressBubbleThird.current.style.border =
        "3px solid rgba(0, 149, 198,1)";
    } else if (nextButton[0] === "fourth") {
      footerBarRef.current.classList.add("sep4");
      progressBubbleTextFirst.current.style.opacity = "100%";
      progressBubbleTextFirst.current.style.color = "white";
      progressBubbleFirst.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleFirst.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";
      footerBarRef.current.classList.add("sep3");
      footerBarRef.current.classList.add("sep2");
      progressBubbleTextSecond.current.style.opacity = "100%";
      progressBubbleTextSecond.current.style.color = "white";
      progressBubbleSecond.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleSecond.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";

      progressBubbleTextThird.current.style.opacity = "100%";
      progressBubbleTextThird.current.style.color = "white";
      progressBubbleThird.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleThird.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";

      progressBubbleTextFourth.current.style.opacity = "100%";
      progressBubbleFourth.current.style.border =
        "3px solid rgba(0, 149, 198,1)";
    } else if (nextButton[0] === "fifth") {
      footerBarRef.current.classList.add("sep5");
      progressBubbleTextFirst.current.style.opacity = "100%";
      progressBubbleTextFirst.current.style.color = "white";
      progressBubbleFirst.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleFirst.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";
      footerBarRef.current.classList.add("sep3");
      footerBarRef.current.classList.add("sep2");
      progressBubbleTextSecond.current.style.opacity = "100%";
      progressBubbleTextSecond.current.style.color = "white";
      progressBubbleSecond.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleSecond.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";

      progressBubbleTextThird.current.style.opacity = "100%";
      progressBubbleTextThird.current.style.color = "white";
      progressBubbleThird.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleThird.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";

      progressBubbleTextFourth.current.style.opacity = "100%";
      progressBubbleTextFourth.current.style.color = "white";
      progressBubbleFourth.current.style.background =
        "linear-gradient(165.96deg, #0095C6 10%, #164282 95%)";
      progressBubbleFourth.current.style.boxShadow =
        "0px 2px 4px rgba(0, 149, 198, 0.5)";

      progressBubbleTextFifth.current.style.opacity = "100%";
      progressBubbleFifth.current.style.border =
        "3px solid rgba(0, 149, 198,1)";
    }
  }, [nextButton, previousButton]);

  return (
    <div>
      <div className="footer-buttons">
        <div
          className="parent-button"
          onClick={() => {
            document.querySelector(
              `.form-${previousButton[0]}-section`
            ).style.display = "none";
            document.querySelector(
              `.form-${previousButton[1]}-section`
            ).style.display = "block";
          }}
        >
          <button className="previous-button">Previous</button>
        </div>
        <div className="parent-button" style={{ marginRight: "0" }}>
          <button
            className="next-button"
            onClick={() => {
              document.querySelector(
                `.form-${nextButton[0]}-section`
              ).style.display = "none";
              document.querySelector(
                `.form-${nextButton[1]}-section`
              ).style.display = "block";
            }}
          >
            Next
          </button>
        </div>
      </div>
      <div className="progress-container">
        <div ref={footerBarRef} className="form-progressbar"></div>
        <div className="form-progressbar full"></div>
        <div className="progress-item">
          <div className="progress-item-unit">
            <div
              className="progress-bubble"
              ref={progressBubbleFirst}
              id="progress-bubble-first"
            >
              <p
                className="progress-bubble-text"
                id="progress-bubble-text-first"
                ref={progressBubbleTextFirst}
              >
                1
              </p>
            </div>
            {/* <div className='progress-text'>CV</div> */}
          </div>
          <div className="progress-item-unit">
            <div
              className="progress-bubble"
              id="progress-bubble-second"
              ref={progressBubbleSecond}
            >
              <p
                className="progress-bubble-text"
                id="progress-bubble-text-second"
                ref={progressBubbleTextSecond}
              >
                2
              </p>
            </div>
            {/* <div className='progress-text'>Personal</div> */}
          </div>
          <div className="progress-item-unit">
            <div
              className="progress-bubble"
              id="progress-bubble-third"
              ref={progressBubbleThird}
            >
              <p
                className="progress-bubble-text"
                id="progress-bubble-text-third"
                ref={progressBubbleTextThird}
              >
                3
              </p>
            </div>
            {/* <div className='progress-text'>Questions</div> */}
          </div>
          <div className="progress-item-unit">
            <div
              className="progress-bubble"
              id="progress-bubble-fourth"
              ref={progressBubbleFourth}
            >
              <p
                className="progress-bubble-text"
                id="progress-bubble-text-fourth"
                ref={progressBubbleTextFourth}
              >
                4
              </p>
            </div>
            {/* <div className='progress-text'>Skills</div> */}
          </div>
          <div className="progress-item-unit">
            <div
              className="progress-bubble"
              id="progress-bubble-fifth"
              ref={progressBubbleFifth}
            >
              <p
                className="progress-bubble-text"
                id="progress-bubble-text-fifth"
                ref={progressBubbleTextFifth}
              >
                5
              </p>
            </div>
            {/* <div className='progress-text'>Your Interrest</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
