import React, { useState, useEffect } from "react";
import "./SecondSection.scss";
import validator from "validator";
import FooterSection from "../FooterSection/FooterSection";

const SecondSection = ({ userData, setMyUserData }) => {
  const [error, setError] = useState(null);

  const [errorInput1, setErrorInput1] = useState(null);
  const [valueInput1, setValueInput1] = useState("");

  const handleChangeInput1 = (event) => {
    setValueInput1(event.target.value);
    setMyUserData({ ...userData, name: event.target.value });
    setErrorInput1(null);
  };

  const handleBlurInput1 = () => {
    if (
      !validator.isAlpha(valueInput1.replace(/\s/g, "")) &&
      !validator.isEmpty(valueInput1)
    ) {
      setErrorInput1("Please enter a valid Name");
    }
  };

  const [errorInput2, setErrorInput2] = useState(null);
  const [valueInput2, setValueInput2] = useState("");

  const handleChangeInput2 = (event) => {
    setValueInput2(event.target.value);
    setMyUserData({ ...userData, email: event.target.value });
    setErrorInput2(null);
  };

  const handleBlurInput2 = () => {
    if (!validator.isEmail(valueInput2)) {
      setErrorInput2("Please enter a Email address");
    }
  };

  const [errorInput3, setErrorInput3] = useState(null);
  const [valueInput3, setValueInput3] = useState("");

  const handleChangeInput3 = (event) => {
    setValueInput3(event.target.value);
    setMyUserData({ ...userData, phone: event.target.value });
    setErrorInput3(null);
  };

  const handleBlurInput3 = () => {
    if (
      !validator.isMobilePhone(
        valueInput3.replace(/\+/g, "").replace(/\s/g, "")
      )
    ) {
      setErrorInput3("Please enter a valid Number");
    }
  };

  const [errorInput4, setErrorInput4] = useState(null);
  const [valueInput4, setValueInput4] = useState("");

  const handleChangeInput4 = (event) => {
    setValueInput4(event.target.value);
    setMyUserData({ ...userData, phone: event.target.value });
    setErrorInput4(null);
  };

  const handleBlurInput4 = () => {
    if (!validator.isAlpha(valueInput4) && !validator.isEmpty(valueInput4)) {
      setErrorInput4("Please enter a valid City Name");
    }
  };

  useEffect(() => {
    if (errorInput1 || errorInput2 || errorInput3 || errorInput4) {
      setError("error");
    } else {
      setError("");
    }
  }, [errorInput1, errorInput2, errorInput3, errorInput4]);

  useEffect(() => {
    if (!valueInput1) {
      setErrorInput1("Please enter a valid Name");
    } else if (!valueInput2) {
      setErrorInput2("Please enter a Email address");
    } else if (!valueInput3) {
      setErrorInput3("Please enter a valid Phone number");
    } else if (!valueInput4) {
      setErrorInput4("Please enter a valid City Name");
    }
  }, [valueInput1, valueInput2, valueInput3, valueInput4]);

  return (
    <>
      <div className="upper-part">
        <p className="url-input-text first-url-input-text">Full Name</p>
        <div className="url-parent">
          <div>
            <input
              type="text"
              value={valueInput1}
              onChange={handleChangeInput1}
              onBlur={handleBlurInput1}
              placeholder={"Name"}
            />
            {errorInput1 && (
              <p className="url-input-text errorMessage">{errorInput1}</p>
            )}
          </div>
        </div>
        <p className="url-input-text">E-mail</p>
        <div className="url-parent">
          <input
            type="text"
            value={valueInput2}
            onChange={handleChangeInput2}
            onBlur={handleBlurInput2}
            placeholder={"oliko@gmail.com"}
          />
          {errorInput2 && (
            <p className="url-input-text errorMessage">{errorInput2}</p>
          )}
        </div>
        <p className="url-input-text">Phone Number</p>
        <div className="url-parent">
          <div>
            <input
              type="text"
              value={valueInput3}
              onChange={handleChangeInput3}
              onBlur={handleBlurInput3}
              placeholder="593 551 652"
            />
            {errorInput3 && (
              <p className="url-input-text errorMessage">{errorInput3}</p>
            )}
          </div>
        </div>
        <p className="url-input-text">City</p>
        <div className="url-parent">
          <input
            type="text"
            value={valueInput4}
            onChange={handleChangeInput4}
            onBlur={handleBlurInput4}
            placeholder="Tbilisi"
          />
          {errorInput4 && (
            <p className="url-input-text errorMessage">{errorInput4}</p>
          )}
        </div>
      </div>
      <FooterSection
        nextButton={["second", "third"]}
        previousButton={["second", "first"]}
        error={error}
      />
    </>
  );
};

export default SecondSection;
