import "../Zailab/Zailab.scss";
import { useLocation } from "react-router-dom";

export default function Zailab() {
  const { company, type, location, communication, experience } =
    useLocation().state;

  return (
    <div className="zailabContainer">
      <div className="zailabLogo">
        <img className="zailabIcon" alt="logo" />
      </div>
      <div className="descriptionZ">
        <ul>
          <i>
            <span className="zIcon">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_742_88009)">
                  <path
                    d="M14.1952 8.51002C15.1157 9.19093 15.9385 10.9053 15.9385 11.9617C15.9385 12.2908 15.6959 12.5575 15.3968 12.5575H15.0668M11.5803 5.71901C12.1758 5.37797 12.5764 4.74055 12.5764 4.01048C12.5764 3.28042 12.1758 2.64299 11.5803 2.30196M2.53401 12.5575H12.0763C12.3754 12.5575 12.618 12.2908 12.618 11.9617C12.618 9.89309 10.8723 8.21618 7.30513 8.21618C3.73795 8.21618 1.99231 9.89309 1.99231 11.9617C1.99231 12.2908 2.23484 12.5575 2.53401 12.5575ZM9.29744 4.01048C9.29744 5.09981 8.40545 5.98288 7.30513 5.98288C6.20481 5.98288 5.31282 5.09981 5.31282 4.01048C5.31282 2.92116 6.20481 2.03809 7.30513 2.03809C8.40545 2.03809 9.29744 2.92116 9.29744 4.01048Z"
                    stroke="#1D4480"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_742_88009">
                    <rect
                      width="15.9385"
                      height="16"
                      fill="white"
                      transform="translate(0.996155 0.0380859)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            {company}
          </i>
          <i>
            <span className="zIcon">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5244 8.46154C14.5244 12.0301 11.719 14.9231 8.25835 14.9231C4.79771 14.9231 1.99231 12.0301 1.99231 8.46154C1.99231 4.89293 4.79771 2 8.25835 2C10.5777 2 12.6027 3.29941 13.6861 5.23077M12.8054 9.17062L14.372 7.55524L15.9385 9.17062M10.6059 10.2235L8.25672 9.41579V6.03846"
                  stroke="#1D4480"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            {type}
          </i>
          <i>
            <span className="zIcon">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.46731 15C8.46731 15 13.9462 10.1304 13.9462 6.47826C13.9462 3.4527 11.4932 1 8.46731 1C5.44143 1 2.98846 3.4527 2.98846 6.47826C2.98846 10.1304 8.46731 15 8.46731 15Z"
                  stroke="#1D4480"
                  strokeWidth="1.2"
                />
                <path
                  d="M10.2177 6.25011C10.2177 7.21661 9.43414 8.00011 8.46753 8.00011C7.50093 8.00011 6.71735 7.21661 6.71735 6.25011C6.71735 5.28361 7.50093 4.50011 8.46753 4.50011C9.43414 4.50011 10.2177 5.28361 10.2177 6.25011Z"
                  stroke="#1D4480"
                  strokeWidth="1.2"
                />
              </svg>
            </span>
            {location}
          </i>
          <i>
            <span className="zIcon">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.47885 4.53809H11.5803M5.47885 8.03809H8.96539M8.738 11.3859L5.09988 15.0381V11.3859H3.73558C2.7728 11.3859 1.99231 10.6024 1.99231 9.63591V2.78809C1.99231 1.82159 2.7728 1.03809 3.73558 1.03809H14.1952C15.158 1.03809 15.9385 1.82159 15.9385 2.78809V9.63591C15.9385 10.6024 15.158 11.3859 14.1952 11.3859H8.738Z"
                  stroke="#1D4480"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            {communication}
          </i>
          <i>
            <span className="zIcon">
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.58519 5.86658H14.9164M5.04978 1.2666V2.46674M13.2977 1.2666V2.4666M13.2977 2.4666H5.20394C3.86293 2.4666 2.77582 3.54111 2.77582 4.86658V12.8666C2.77582 14.1921 3.86293 15.2666 5.20394 15.2666H13.2977C14.6387 15.2666 15.7258 14.1921 15.7258 12.8666L15.7258 4.86658C15.7258 3.54111 14.6387 2.4666 13.2977 2.4666ZM9.25082 8.2666V10.2666M9.25082 10.2666V12.2666M9.25082 10.2666H11.2743M9.25082 10.2666H7.22738"
                  stroke="#1D4480"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            {experience}
          </i>
        </ul>
      </div>
      <div className="buttonZ">
        <button
          style={{ cursor: "pointer" }}
          onClick={() => {
            document
              .querySelector("#form-top")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          Apply now
        </button>
      </div>
      <div className="shareZ">
        Share with{" "}
        <span className="shareIcon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.77375 0C0.794437 0 0 0.77025 0 1.71919V22.2814C0 23.2304 0.794531 24 1.77375 24H22.2262C23.2059 24 24 23.2303 24 22.2811V1.71919C24 0.77025 23.2059 0 22.2262 0H1.77375ZM7.29317 9.28002V20.0849H3.7018V9.28002H7.29317ZM7.52989 5.93849C7.52989 6.97536 6.75036 7.80505 5.49824 7.80505L5.47471 7.80496C4.26936 7.80496 3.49011 6.97527 3.49011 5.9384C3.49011 4.87799 4.29289 4.07146 5.52205 4.07146C6.75036 4.07146 7.50646 4.87799 7.52989 5.93849ZM12.8721 20.0849H9.28105C9.28105 20.0849 9.32811 10.294 9.28124 9.2804H12.8724V10.8098C13.3498 10.0737 14.2042 9.02671 16.1091 9.02671C18.4719 9.02671 20.2434 10.5711 20.2434 13.8897V20.0849H16.6524V14.3052C16.6524 12.8526 16.1324 11.8618 14.8332 11.8618C13.8409 11.8618 13.25 12.53 12.9905 13.1754C12.8956 13.4058 12.8721 13.7291 12.8721 14.0516V20.0849Z"
              fill="#0A66C2"
            />
          </svg>
        </span>{" "}
        <span className="shareIcon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_742_87974)">
              <rect width="24" height="24" rx="3" fill="#337FFF" />
              <path
                d="M15.7916 13.3208L16.2638 10.3232H13.3549V8.37471C13.3549 7.55504 13.761 6.7541 15.0596 6.7541H16.4007V4.20141C15.6197 4.07697 14.8306 4.00966 14.0396 4C11.6455 4 10.0824 5.43794 10.0824 8.03747V10.3232H7.42856V13.3208H10.0824V20.5714H13.3549V13.3208H15.7916Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_742_87974">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
    </div>
  );
}
