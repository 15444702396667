import "./AboutCompany.scss";

import Zailab from "../Zailab/Zailab";
import PostHeader from "../PostHeader/PostHeader";
import { useLocation } from "react-router-dom";

export default function AboutCompany() {
  const { aboutCompany, AboutYou, YourResponsiblities } = useLocation().state;

  return (
    <>
      <div className="postHeader">
        <PostHeader />
      </div>
      <div className="parentCompany">
        <div className="aboutContainer">
          <h1 className="aboutC">About Company</h1>
          <p className="descriptionC">{aboutCompany}</p>
          <h1 className="aboutC">Job Description</h1>
          <h3 className="sub_description">About you</h3>
          <p className="descriptionC">{AboutYou}</p>
          <h1 className="aboutC">Your responsibilities</h1>
          <ul className="about_lists">
            {YourResponsiblities &&
              YourResponsiblities.map((responsiblity, index) => (
                <li key={index}>{responsiblity}</li>
              ))}
          </ul>
          <div className="buttonC">
            <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                document
                  .querySelector("#form-top")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Apply now
            </button>
          </div>
        </div>
        <div className="zailabClass">
          <Zailab />
        </div>
      </div>
    </>
  );
}
