import './Footer.scss';

export default function Footer(){
    return (
        <div className='footer2'>

<div className="footerContainer">
  <div className="svgBG">
  <div className='gradientfooter'>
  <h2 className="mainTitle">
    CONTACT
  </h2>
  <p className="secondTitle">In case of any questions,please,<br/>  feel free to send message in a bottle!</p>
  <h3 className="contactTitle">info@codevelop.io<br/> +995 909 000 00</h3>
  
  <p className="footerTitle">©Codevelop Technologies GmbH. 2022 All right sererved.</p>

  </div>
  </div>
</div>
</div>
    )
}