import React from "react";

import AboutCompany from "../../components/AboutCompany/AboutCompany";
import Form from "../../components/Form/Form";
import Footer from "../../components/Footer /Footer";

const Description = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <AboutCompany />
      <Form />
      <Footer />
    </>
  );
};

export default Description;
