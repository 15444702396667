import "../PostHeader/PostHeader.scss";
import { useLocation } from "react-router-dom";

export default function PostHeader() {
  const { title, posted } = useLocation().state;

  return (
    <div className="postContainer">
      <div className="postLogo">
        <img className="postImg" alt="logo" />
      </div>
      <div className="postText">
        <div className="postTitle">{title}</div>
        <p className="post_subtitle">
          <span className="postSvg">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.02051 21.1052V21M14.4189 21.1052V21M14.4189 15.4V15.2947M20.1064 15.4V15.2947M3.75488 9.79996H23.6611M6.3278 1.75V3.85025M20.8174 1.75V3.84999M20.8174 3.84999H6.59863C4.24279 3.84999 2.33301 5.73038 2.33301 8.04997V22.05C2.33301 24.3696 4.24279 26.25 6.59863 26.25H20.8174C23.1732 26.25 25.083 24.3696 25.083 22.05L25.083 8.04997C25.083 5.73038 23.1732 3.84999 20.8174 3.84999Z"
                stroke="#164282"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          Posted: {posted}
        </p>
      </div>
      <div className="full-width-border"></div>
    </div>
  );
}
