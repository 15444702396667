import React, { useEffect, useState } from "react";
import JobPosition from "../../components/JobPositions/JobPosition";
import jobsMainLogo from "../../assets/jobs-main-logo.png";
import Footer from "../../components/Footer /Footer";
import "./Jobs.scss";
import data from "../../jobs.json";

const Jobs = () => {
  const [positionsData, setPositionsData] = useState(null);
  const [cityFilter, setCityFilter] = useState("All");

  useEffect(() => {
    let uniqueArray = data.positions.filter((obj, index, self) => {
      return index === self.findIndex((t) => t.location === obj.location);
    });
    setPositionsData(uniqueArray);
  }, []);

  const handleClick = (position, currentDOM) => {
    if (position === "All") {
      setCityFilter("All");
    } else {
      setCityFilter(position.location);
    }

    document.querySelectorAll(".locations-item").forEach((element) => {
      element.style.backgroundColor = "#FFFFFF";
      element.style.color = "#0095C6";
    });
    currentDOM.target.style.backgroundColor = "#0095C6";
    currentDOM.target.style.color = "#FFFFFF";
  };

  return (
    <div className="jobs-container">
      <div className="jobs-landing">
        <div className="hero-image-parent">
          <div className="parent">
            <img
              className="logo-and-text"
              src={jobsMainLogo}
              alt={"jobs main logo"}
            />
            <p className="phrase-1">join our team</p>
            <p className="phrase-2">of pirates!</p>
            <p className="phrase-3">
              For our team expansion,
              <br />
              we are looking for people with
              <br />
              creativity and entrepreneurial thinking.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="main-landing">
          <div>
            <p className="open-jobs-positions-title">Open job Positions</p>
          </div>
          <div className="locations">
            <div className="locations-item">Location</div>
            <div
              className="locations-item pill"
              style={{
                backgroundColor: "#0095C6",
                color: "#FFFFFF",
                cursor: "pointer",
              }}
              onClick={(currentDOM) => handleClick("All", currentDOM)}
            >
              All
            </div>

            {positionsData &&
              positionsData.map((position, index) => (
                <div
                  key={index}
                  className="locations-item pill"
                  style={{ cursor: "pointer" }}
                  onClick={(currentDOM) => handleClick(position, currentDOM)}
                >
                  {position.location}
                </div>
              ))}
          </div>
        </div>
        <JobPosition cityFilter={cityFilter} data={data} />
      </div>
      <Footer />
    </div>
  );
};

export default Jobs;
