import React, { useState } from "react";

function Dropzone({ userData, setMyUserData }) {
  const [file, setFile] = useState(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (
      droppedFile.type === "application/pdf" &&
      droppedFile.size <= 10 * 1024 * 1024
    ) {
      setFile(droppedFile);
      setMyUserData({ ...userData, file: droppedFile });
    } else {
      alert("Only PDF files less than 10 MB are allowed.");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = (e) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "application/pdf";
    fileInput.onchange = handleFileSelect;
    fileInput.click();
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile.type === "application/pdf" &&
      selectedFile.size <= 10 * 1024 * 1024
    ) {
      setFile(selectedFile);
      setMyUserData({
        file: selectedFile,
      });
    } else {
      alert("Only PDF files less than 10 MB are allowed.");
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="upload-file"
      onClick={handleClick}
    >
      {file ? (
        <p className="upload-file-text">File name: {file.name}</p>
      ) : (
        <p className="upload-file-text">Drop your CV file here</p>
      )}
    </div>
  );
}

export default Dropzone;
