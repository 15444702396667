import React, { useState, useEffect } from "react";
import StarRatingComponent from "react-star-rating-component";

import "./RatingStars.scss";

const RatingStars = ({ icon, iconFilled, onStarClick }) => {
  const [rating, setRating] = useState(4);

  const handleStarClick = (nextValue) => {
    setRating(nextValue);
    // onStarClick(nextValue);
  };

  useEffect(() => {
    console.log("children");
  }, []);

  const renderStarIcon = (index, value) => {
    return value < index ? (
      <img className="ratingStar" src={icon} alt="star" />
    ) : (
      <img className="ratingStarFilled" src={iconFilled} alt="star" />
    );
  };

  // useEffect(() => {
  //   if (skill && rating) {
  //     let object = {};
  //     const propertyName = skill.replace(/\s/g, "");
  //     object[propertyName] = rating;
  //     setMyUserData({ ...userData, ...object });
  //     setRating(rating);
  //   }
  // }, [rating, setRating]);

  return (
    <div>
      <StarRatingComponent
        name="rating"
        value={rating}
        onStarClick={handleStarClick}
        renderStarIcon={renderStarIcon}
        starCount={10}
      />
    </div>
  );
};

export default RatingStars;
