import React, { useEffect, useState } from "react";
import "./FirstSection.scss";
import validator from "validator";
import Dropzone from "../../DropZone/DropZone";
import FooterSection from "../FooterSection/FooterSection";
import { useSelector } from "react-redux";

const FirstSection = ({ userData, setMyUserData }) => {
  // rest of the code
  const state = useSelector((state) => state);

  useEffect(() => {
    console.log("state", state);
  }, [state]);

  const [error, setError] = useState(null);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (userData.file) {
      setError(null);
    }
  }, [userData]);

  useEffect(() => {
    if (!value && !userData.file) {
      setError("Please enter a valid URL address");
    }
  }, [userData.file, value]);

  const handleChange = (event) => {
    setValue(event.target.value);
    setMyUserData({ ...userData, url: event.target.value });
    setError(null);
  };
  const handleBlur = () => {
    if (!userData.file && !validator.isURL(value)) {
      setError("Please enter a valid URL address");
    }
  };

  return (
    <>
      <div className="upper-part">
        <Dropzone userData={userData} setMyUserData={setMyUserData} />
        <p className="url-input-text">Or Add URL to your CV</p>
        <div className="url-parent">
          <div>
            <input
              type="text"
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={"https://"}
            />
            {error && <p className="url-input-text errorMessage">{error}</p>}
          </div>
        </div>
      </div>
      <FooterSection
        nextButton={["first", "second"]}
        previousButton={["first", "first"]}
        error={error}
      />
    </>
  );
};

export default FirstSection;
