import React, { useState, useEffect } from "react";
import AdobePhotoshopImage from "../../../assets/icons/photoshop.svg";
import AdobeIllustratorImage from "../../../assets/icons/illustrator.svg";
import HeartFilledImage from "../../../assets/icons/heart-filled.svg";
import HeartImage from "../../../assets/icons/heart.svg";
import RatingStars from "../../RatingStars/RatingStars";
import "./FirthSection.scss";
import { useLocation } from "react-router-dom";

const FifthSection = () => {
  const location = useLocation();
  const [skillsImages, setSkillsImages] = useState(null);
  const [skillsTitles, setSkillsTitles] = useState(null);

  useEffect(() => {
    let skills = location.state.skills;
    let skillsImagesNoSpaces = [];
    skills.forEach((skill) => {
      skillsImagesNoSpaces.push(skill.replace(/\s/g, "") + "Image");
    });
    setSkillsImages(skillsImagesNoSpaces);
    setSkillsTitles(skills);
  }, [location.state.skills]);

  const PickImage = (image) => {
    switch (image) {
      case "AdobePhotoshopImage":
        return AdobePhotoshopImage;
      case "AdobeIllustratorImage":
        return AdobeIllustratorImage;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="parent-form title">
        <p>Rate your Interests</p>
        <hr />
      </div>
      <div className="parent-form-knowledge">
        {skillsImages &&
          skillsImages.map((image, index) => (
            <div className="key-item" key={Math.random()}>
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    className="image-tool"
                    src={PickImage(image)}
                    alt="current level"
                  />
                </div>
                <div>
                  <div style={{ marginLeft: "1rem", textAlign: "start" }}>
                    <div>
                      <p className="tool-title">{skillsTitles[index]}</p>
                    </div>
                    <div>
                      <RatingStars
                        iconFilled={HeartFilledImage}
                        icon={HeartImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FifthSection;
