import React, { useEffect, useState } from "react";
import "./ThirdSection.scss";
import validator from "validator";
import FooterSection from "../FooterSection/FooterSection";

const ThirdSection = ({ userData, setMyUserData }) => {
  const [errorInput1, setErrorInput1] = useState(null);
  const [valueInput1, setValueInput1] = useState("");
  const [error, setError] = useState(null);

  const handleChangeInput1 = (event) => {
    setValueInput1(event.target.value);
    setMyUserData({ ...userData, salary: event.target.value });
    setErrorInput1(null);
  };
  const handleBlurInput1 = () => {
    if (!validator.isNumeric(valueInput1)) {
      setErrorInput1("Please enter a valid Number");
    }
  };

  const [errorInput2, setErrorInput2] = useState(null);
  const [valueInput2, setValueInput2] = useState("");

  const handleChangeInput2 = (event) => {
    setValueInput2(event.target.value);
    setMyUserData({ ...userData, startDate: event.target.value });
    setErrorInput2(null);
  };
  const handleBlurInput2 = () => {
    function isDateInFuture(dateString) {
      var parts = dateString.split("/");
      var day = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10) - 1;
      var year = parseInt(parts[2], 10);

      var date = new Date(year, month, day);
      var now = new Date();

      return date > now;
    }

    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/;
    if (!validator.matches(valueInput2, regex)) {
      setErrorInput2("Please enter a valid date format: DD/MM/YYYY");
    } else {
      if (!isDateInFuture(valueInput2)) {
        setErrorInput2("Please enter a valid date in the future");
      }
    }
  };

  const [errorInput3, setErrorInput3] = useState(null);
  const [valueInput3, setValueInput3] = useState("");

  const handleChangeInput3 = (event) => {
    setValueInput3(event.target.value);
    setMyUserData({ ...userData, whyYouApply: event.target.value });
    setErrorInput3(null);
  };
  const handleBlurInput3 = () => {
    if (!validator.isAlphanumeric(valueInput3)) {
      setErrorInput3("Please enter a valid response");
    }
  };

  useEffect(() => {
    if (errorInput1 || errorInput2 || errorInput3) {
      setError("error");
    } else {
      setError(null);
    }
  }, [errorInput2, errorInput1, errorInput3]);

  useEffect(() => {
    if (!valueInput1) {
      setErrorInput1("Please enter a valid Number");
    } else if (!valueInput2) {
      setErrorInput2("Please enter a valid date format: DD/MM/YYYY");
    } else if (!valueInput3) {
      setErrorInput3("Please enter a valid response");
    }
  }, [valueInput1, valueInput2, valueInput3]);

  return (
    <>
      {" "}
      <div className="upper-part">
        <p className="url-input-text first-url-input-tex">Salary expectation</p>
        <div className="url-parent">
          <input
            type="text"
            value={valueInput1}
            onChange={handleChangeInput1}
            onBlur={handleBlurInput1}
            placeholder={"1000"}
          />
          {errorInput1 && (
            <p className="url-input-text errorMessage">{errorInput1}</p>
          )}
        </div>
        <p className="url-input-text">Earliest start date?</p>
        <div className="url-parent">
          <input
            type="text"
            value={valueInput2}
            onChange={handleChangeInput2}
            onBlur={handleBlurInput2}
            placeholder={"DD/MM/YYYY"}
          />
          {errorInput2 && (
            <p className="url-input-text errorMessage">{errorInput2}</p>
          )}
        </div>
        <p className="url-input-text">Why are you applying to us?</p>
        <div className="url-parent">
          <textarea
            type="text"
            value={valueInput3}
            onChange={handleChangeInput3}
            onBlur={handleBlurInput3}
            placeholder="The quick brown fox jumps over the lazy dog"
            style={{ height: "200px" }}
          />
          {errorInput3 && (
            <p className="url-input-text errorMessage">{errorInput3}</p>
          )}
        </div>
      </div>
      <FooterSection
        nextButton={["third", "fourth"]}
        previousButton={["third", "second"]}
        error={error}
      />
    </>
  );
};

export default ThirdSection;
