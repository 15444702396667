import './App.css';
// import ContactUs from './pages/ContactUs/ContactUs';
import Description from './pages/Description/Description'
import Jobs from './pages/Jobs/Jobs';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<Jobs />} />
          <Route path="/description" element={<Description />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
